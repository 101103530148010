import "../css/app.pcss";
import "./utils/vh-screen";

import gsap from "gsap";

const tl = gsap.timeline();
tl.to("body", {
	backgroundColor: "#7479E2",
	duration: 0.5,
	delay: 0.25,
});
tl.from(".piece", {
	opacity: 0,
	duration: 0.25,
	stagger: {
		from: "random",
		amount: 2.5,
	},
});
tl.from(".strapline", {
	opacity: 0,
	duration: 1,
});
